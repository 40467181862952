import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyLogin } from "../../api/manageLoginAPI";
import arista_logo from "../../res/arista_logo.png";
import mazda_logo from "../../res/mazda_logo.png";
import wheeltek_logo from "../../res/wheeltek_logo.png";
import { getCookie } from "../../utils/miscUtils";
import { RxCross2 } from "react-icons/rx";
import { useAlertToastHook } from "../../hooks/alertToastHook";


const Login = () => {

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const navigate = useNavigate();
    const [errorMessage , setErrorMessage] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const { addMessage } = useAlertToastHook();
    const [pathFrom, setPathFrom] = useState<string>(localStorage.getItem("pathFrom:") || "");

    function checkCookie() {
        var user = getCookie("token");

        if (user !== "") {
            setLoading(false)
            addMessage("Login Successful" , true)
            navigate("/management/salesperson-with-person")
        }
        else{
            setLoading(false)
            console.log("error: empty cookie")
            setErrorMessage("Error Please Try Again")
            return
        }
      }

    const saveJWTCookie =  (jwt : string) =>{

        const date = new Date();
        date.setTime(date.getTime() + (3600 * 24 * 1000));
        let expires = "expires=" + date.toUTCString();
        document.cookie = "token=" + jwt + ";" + expires + ";path=/ ;";
    }

    const handleLogin = async  () => {
        if(pathFrom){localStorage.removeItem("pathFrom:")}
        setLoading(true)

        if(username === "" || password === "")
        {
            console.log("error: empty field")
            setLoading(false);
            localStorage.setItem("username", username);
            return
        }

        const userInfo = {
            username: username,
            password: password
        }

        const token = await verifyLogin(userInfo)
        if(!token){
            setErrorMessage("Incorrect username or password")
            setLoading(false)
            return
        }
        saveJWTCookie(token)
        checkCookie();
    }

    const background = "https://w0.peakpx.com/wallpaper/715/376/HD-wallpaper-vehicles-motorcycle-tvs-apache-rr-310.jpg"

    return (
        <>
            <div className="flex mx-auto justify-center h-screen">
                <img className="w-screen h-screen absolute inset-0 object-cover object-center " src={background} />
                <div className="my-auto text-left md:w-1/2 md:h-1/2 xl:w-[400px] xl:h-[400px] max-w-[400px] max-h-[400px] z-10">
                    <div className="bg-white opacity-90 shadow-lg rounded-md px-10 pt-5 pb-10">

                        {/*Logo*/}
                        <div className="">
                            <img src={mazda_logo} alt="TVSD" className="w-30 h-12 mx-auto"/>
                        </div>
                        <div className="pt-2 pb-2">
                            <h1 className="text-lg font-bold">Sign In</h1>
                        </div>

                        {/*Login Form*/}
                        <form onSubmit={(event) =>{event.preventDefault()}}>
                        <div>
                            <label className="font-bold">Username</label>
                            <input
                                className="bg-gray-50 border border-gray-900 text-gray-900 text-sm rounded-lg block w-full p-2.5 hover:border-blue-900"
                                placeholder={"John Doe"}
                                required={true}
                                type="text"
                                onChange={(e) => { setUsername(e.target.value) }}
                            />
                        </div>

                        <div className="pt-3">
                            <label className="font-bold">Password</label>
                            <input
                                className="bg-gray-50 border border-gray-900 text-gray-900 text-sm rounded-lg block w-full p-2.5 hover:border-blue-900"
                                required={true}
                                placeholder={"********"}
                                type="password"
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                        </div>

                        <div className="pt-4">
                            {
                                !loading?
                                
                                <button className="justify-center shadow-lg text-white rounded-lg w-full p-1 mt-5 hover:bg-blue-900 opacity-100 bg-blue-600"
                                onClick={()=>{handleLogin()}}>
                                Sign In</button> 
                                
                                :
                                <button className="justify-center shadow-lg text-white rounded-lg w-full p-1 mt-5 opacity-100 bg-blue-700">
                                    <div className="w-[23px] h-[23px] border-b-2 border-gray-200 rounded-full animate-spin mx-auto"/>
                                </button>
                            }
                        </div>
                        </form>
                    </div>

                    {/*Error Message */}
                    {
                        errorMessage !== "" ? 
                            <div className="pt-2 w-full">
                                <div className=" bg-red-200 opacity-90 rounded-md flex justify-between p-2 border-red-300 border-2">
                                    <p className="text-black text-sm">{errorMessage}</p>
                                    <button onClick={()=>setErrorMessage("")}><RxCross2 color="red"/></button>
                                </div>
                            </div>
                            : 
                            null
                    }
                    {
                        pathFrom !== "" ?
                            <div className="pt-2 w-full">
                                <div className=" bg-red-200 opacity-90 rounded-md flex justify-between p-2 border-red-300 border-2">
                                    <p className="text-black text-sm">Please login to continue</p>
                                    <button onClick={()=>{localStorage.removeItem("pathFrom:"); setPathFrom("")}}><RxCross2 color="red"/></button>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </>
    )
}

export default Login;